import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { updateLocale } from "../../../services/users.service";
import { StepNameProps } from "../../../@types";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import { useMutation } from "@tanstack/react-query";

// Validation schema using Yup
const validationSchema = Yup.object({
  companyName: Yup.string().required("Nome é obrigatório"),
  companyAddress: Yup.string().required("Endereço é obrigatório"),
  pc4: Yup.string().required("CP4 é obrigatório"),
  pc3: Yup.string().required("CP3 é obrigatório"),
  nif: Yup.string()
    .matches(/^\d{9}$/, "NIF must be exactly 9 digits")
    .required("NIF is required"),
});

export default function StepName({ onNext, onSkip }: StepNameProps) {
  const { t, i18n } = useTranslation("onboarding");

  const updateLocaleMutation = useMutation({
    mutationKey: ["localeUpdate"],
    mutationFn: updateLocale,
  });

  const getFormattedLang = (lang: string) => {
    if (lang === "english") {
      return "en";
    } else if (lang === "português") {
      return "pt";
    } else {
      return "en";
    }
  };

  const changeLanguageHandler = (lang: string) => {
    const formattedLang = getFormattedLang(lang);
    i18n.changeLanguage(formattedLang);
    localStorage.setItem("language", formattedLang);

    const backendLang = lang === "english" ? "en" : "pt";
    updateLocaleMutation.mutate({ locale: backendLang as "pt" | "en" });
  };

  const onSubmit = ({
    nif,
    companyName,
    address,
    mobileNumber,
    phoneNumber,
    website,
    pc3,
    pc4,
    activity,
  }: any) => {
    onNext({
      nif,
      companyName,
      address,
      mobileNumber,
      phoneNumber,
      website,
      pc3,
      pc4,
      activity,
    });
  };

  return (
    <Formik
      initialValues={{
        nif: "",
        companyName: "",
        address: "",
        mobileNumber: "",
        phoneNumber: "",
        website: "",
        pc3: "",
        pc4: "",
        activity: "",
      }}
      validationSchema={validationSchema} // Add validation schema here
      onSubmit={onSubmit}
    >
      {({ values, handleChange, setFieldValue, isValid, errors, touched }) => {
        const noFieldsEmpty =
          values.address.length > 0 &&
          values.companyName.length > 0 &&
          values.pc3.length > 0 && values.pc4.length > 0 &&
          values.nif.length > 0;
        const disabled = !isValid || !noFieldsEmpty;

        return (
          <div className="flex flex-row min-h-screen">
            {/* Left side - Form */}
            <div className="w-full lg:w-1/2 flex items-center justify-center bg-gray-50 p-8">
              <div className="w-full max-w-lg">
                <h1 className="text-2xl font-bold leading-7 mb-4">
                  Dados Empresa
                </h1>
                <h2 className="text-base mb-6">
                  Por favor insira os dados da empresa
                </h2>
                <Form className="space-y-4">
                  {/* NIF */}
                  <div>
                    <label className="text-base mb-1 block">NIF*</label>
                    <Field
                      type="text"
                      placeholder="NIF"
                      className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                        touched.nif && errors.nif ? "border-red-800" : ""
                      }`}
                      id="nif"
                      name="nif"
                      maxLength={9}
                      value={values?.nif}
                      onChange={handleChange}
                      required
                    />
                    {touched.nif && errors.nif ? (
                      <div className="text-red-800 text-sm">{errors.nif}</div>
                    ) : null}
                  </div>

                  {/* Company Name */}
                  <div>
                    <label className="text-base mb-1 block">Nome*</label>
                    <Field
                      type="text"
                      placeholder="Nome"
                      className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                        touched.companyName && errors.companyName
                          ? "border-red-800"
                          : ""
                      }`}
                      id="companyName"
                      name="companyName"
                      value={values?.companyName}
                      onChange={handleChange}
                      required
                    />
                    {touched.companyName && errors.companyName ? (
                      <div className="text-red-800 text-sm">
                        {errors.companyName}
                      </div>
                    ) : null}
                  </div>

                  {/* First Name */}
                  <div>
                    <label className="text-base mb-1 block">
                      Endereço*
                    </label>
                    <Field
                      type="text"
                      placeholder="Endereço"
                      className={`border  focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                        touched.address && errors.address
                          ? "border-red-800"
                          : ""
                      }`}
                      id="companyAddress"
                      name="companyAddress"
                      value={values?.address}
                      onChange={handleChange}
                      required
                    />
                    {touched.address && errors.address ? (
                      <div className="text-red-800 text-sm">
                        {errors.address}
                      </div>
                    ) : null}
                  </div>

                  {/* cp4 */}
                  <div>
                    <label className="text-base mb-1 block">
                      CP4*
                    </label>
                    <Field
                      type="text"
                      placeholder="4 digitos"
                      maxLength={4}
                      className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                        touched.pc4 && errors.pc4 ? "border-red-800" : ""
                      }`}
                      id="pc4"
                      name="pc4"
                      value={values?.pc4}
                      onChange={handleChange}
                      required
                    />
                    {touched.pc4 && errors.pc4 ? (
                      <div className="text-red-800 text-sm">{errors.pc4}</div>
                    ) : null}
                  </div>

                  <div>
                    <label className="text-base mb-1 block">
                      CP3*
                    </label>
                    <Field
                      type="text"
                      placeholder="3 digitos"
                      maxLength={3}
                      className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                        touched.pc3 && errors.pc3 ? "border-red-800" : ""
                      }`}
                      id="pc3"
                      name="pc3"
                      value={values?.pc3}
                      onChange={handleChange}
                      required
                    />
                    {touched.pc3 && errors.pc3 ? (
                      <div className="text-red-800 text-sm">{errors.pc3}</div>
                    ) : null}
                  </div>

                  <div>
                    <label className="text-base mb-1 block">
                      Atividade
                    </label>
                    <Field
                      type="text"
                      placeholder="Atividade"
                      className={`border  focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                        touched.activity && errors.activity
                          ? "border-red-800"
                          : ""
                      }`}
                      id="activity"
                      name="activity"
                      value={values?.activity}
                      onChange={handleChange}
                      
                    />
                    {touched.activity && errors.activity ? (
                      <div className="text-red-800 text-sm">
                        {errors.activity}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <label className="text-base mb-1 block">
                      Nº de telefone
                    </label>
                    <Field
                      type="text"
                      placeholder="9 digitos"
                      maxLength={9}
                      className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                        touched.phoneNumber && errors.phoneNumber ? "border-red-800" : ""
                      }`}
                      id="phoneNumber"
                      name="phoneNumber"
                      value={values?.phoneNumber}
                      onChange={handleChange}
                      
                    />
                    {touched.phoneNumber && errors.phoneNumber ? (
                      <div className="text-red-800 text-sm">{errors.phoneNumber}</div>
                    ) : null}
                  </div>

                  <div>
                    <label className="text-base mb-1 block">
                      Url
                    </label>
                    <Field
                      type="text"
                      placeholder="Website - URL"
                      className={`border  focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                        touched.website && errors.website
                          ? "border-red-800"
                          : ""
                      }`}
                      id="website"
                      name="website"
                      value={values?.website}
                      onChange={handleChange}
                      
                    />
                    {touched.website && errors.website ? (
                      <div className="text-red-800 text-sm">
                        {errors.website}
                      </div>
                    ) : null}
                  </div>

                  {/* Submit Button */}
                  <div className="mt-6">
                    <CustomButton
                      className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                        disabled
                          ? "bg-gray-400"
                          : "bg-indigo-600 hover:bg-indigo-700"
                      }`}
                      type="submit"
                      disabled={disabled}
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.MEDIUM}
                    >
                      Próximo
                    </CustomButton>
                  </div>
                </Form>
              </div>
            </div>

            {/* Right side - Company & Person Representation */}
            <div className="hidden lg:flex w-1/2 items-center justify-center bg-indigo-200 p-8">
              <div className="text-center">
                <h1 className="text-3xl font-bold mb-4 mt-12">
                  {values.companyName || ""}
                </h1>
                <BusinessIcon
                  style={{
                    fontSize:
                      window.innerWidth < 640
                        ? "40px"
                        : window.innerWidth < 768
                        ? "60px"
                        : "240px",
                  }}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
