import axiosInstance from ".";
import { IUser, UpdateVariables } from "../@types";

export async function updateUserDetails(variables: UpdateVariables) : Promise<IUser | null> {

    const { userDetails } = variables
    try {
        const response = await axiosInstance.patch('/users/details', userDetails);
        return response.data;
    } catch (error: any) {
        if (error.response) {
            const { status, data } = error.response;
            if (status === 404) {
                console.error(`Endpoint not found`);
            } else {
                console.error(`API error: ${status} - ${data}`);
            }
        } else if (error.request) {
            console.error(`Network error: ${error.request}`);
        } else {
            console.error(`Error: ${error.message}`);
        }
        return null;
    }
}

export async function patchOnboardingData(values: any): Promise<void> {
    try {
      console.log('Request data:', values); // Log the request data
      await axiosInstance.patch('/onboarding', values);
    } catch (error: any) {
      console.error('Error patching onboarding:', error);
      console.error('Response data:', error.response?.data); // Log the response data if available
      throw error
    }
  }